<template>
    <div>
        <component :is="componentModule" :deviceToken="token" @redirectFromLanding="login()"></component>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
    data() {
        return {
            partner_id: '',
            language_type: '',
            componentModule: {},
            token: null
        }
    },
    computed: {
        ...mapGetters(["getToken"])
    },
    watch: {
        $route() {
            this.partnerRedirection();
        },
        // getToken(val) {
        //     if (val) {
        //         this.token = val;
        //     }
        // }
    },
    methods: {
        ...mapMutations(["setRtl"]),
        partnerRedirection () {
            this.partner_id = this.$route.params && this.$route.params.id ? this.$route.params.id : '';
            this.language_type = this.$route.params && this.$route.params.lang ? this.$route.params.lang : '';

            if (this.language_type == 'en') {
                this.$i18n.locale = 'eng';
                this.setRtl(false);
            } else if (this.language_type == 'ar') {
                this.$i18n.locale = 'ara';
                this.setRtl(true);
            }

            localStorage.setItem("setDisplayLanguageCode", this.$i18n.locale);

            this.componentModule = () => import(/* webpackChunkName: 'landingpage' */ '@/components/Templates/landing_page_token.vue')
        },
        login() {
            this.$emit("openAuth", true);
            this.$router.push('/');
        }
    },
    created() {
        this.partnerRedirection();
    }
}
</script>

<style>

</style>